import React from "react";

import Link from "components/Common/Link";
import { useDropdown } from "components/Dropdowns/Dropdown";
import Config from "config/user";
import { UserType, handleLogout } from "services/auth";
import { redirect } from "libs/utils";

type Props = {
  user: UserType | null;
};

export default function UserDropdown({ user }: Props) {
  const { dropdownRef, dropdownOpen, toggleDropdown } =
    useDropdown<HTMLDivElement>();

  return (
    <>
      <div className="relative">
        <button
          type="button"
          className="flex items-center focus:outline-none"
          aria-label="toggle profile dropdown"
          onClick={toggleDropdown}
        >
          <div className="w-8 h-8 overflow-hidden shadow-lg rounded-full">
            <svg
              className="object-cover w-full h-full"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>

          <h3 className="mx-2 text-sm font-medium text-foreground-500 hidden md:block">
            {user?.username || "Guest"}
          </h3>
        </button>
        <div
          ref={dropdownRef}
          className={
            (dropdownOpen ? "block " : "hidden ") +
            "absolute right-0 bg-white border border-gray-200 text-gray-500 z-50 py-3 rounded-md shadow-lg w-48 list-none text-left"
          }
        >
          <div className="inline-flex w-full border-b border-gray-300 py-2 px-4">
            <svg
              className="object-cover w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="pl-2">{user?.username || "Guest"}</span>
          </div>

          {Config.dropdown.map((link, i) => (
            <Link
              key={i}
              className="block py-3 pl-4 pr-8 w-full text-sm capitalize text-gray-700 hover:bg-primary-500 hover:text-white"
              href={link.href}
              icon={link.icon}
            >
              {link.title}
            </Link>
          ))}

          <Link
            icon="sign-out-alt"
            className="block py-3 pl-4 pr-8 w-full text-sm capitalize text-gray-700 hover:bg-primary-500 hover:text-white"
            href="/"
            onClick={handleLogout}
          >
            <span className="ml-2 min-w-40">Log Out</span>
          </Link>
        </div>
      </div>
    </>
  );
}
