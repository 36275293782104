import React, { useEffect, useState, useRef, MouseEventHandler } from "react";
import Link from "components/Common/Link";
import Icon from "components/Common/Icon";

export type MenuItem = {
  href: string;
  text: string;
  icon?: string;
};

type Props = {
  text?: string;
  icon?: string;
  menus: Array<MenuItem>;
};

export function useDropdown<T extends HTMLElement>() {
  const dropdownRef = useRef<T>(null);
  const [dropdownOpen, setDropdown] = useState<boolean>(false);

  const openDropdown = () => {
    setDropdown(true);
  };

  const closeDropdown = () => {
    setDropdown(false);
  };

  const toggleDropdown: MouseEventHandler = (e) => {
    e.preventDefault();
    dropdownOpen ? closeDropdown() : openDropdown();
  };

  const handleCloseDropdown = (e: MouseEvent | TouchEvent) => {
    if (
      !dropdownOpen &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as HTMLElement)
    ) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleCloseDropdown);
    //document.addEventListener("touchstart", handleCloseDropdown);

    return () => {
      document.removeEventListener("mousedown", handleCloseDropdown);
      //document.removeEventListener("touchstart", handleCloseDropdown);
    };
  }, []);

  return {
    dropdownRef,
    dropdownOpen,
    openDropdown,
    closeDropdown,
    toggleDropdown,
  };
}

export default function Dropdown({ text, icon, menus }: Props) {
  const { dropdownRef, dropdownOpen, toggleDropdown } =
    useDropdown<HTMLDivElement>();

  return (
    <>
      <div className="relative">
        <a
          className="relative z-10 block rounded-md text-xs uppercase font-bold"
          href="#"
          onClick={toggleDropdown}
        >
          {icon ? (
            <Icon icon={icon} />
          ) : text ? (
            text
          ) : (
            <Icon icon="chevron-circle-down" />
          )}
        </a>
        <div
          ref={dropdownRef}
          className={
            (dropdownOpen ? "block" : "hidden") +
            " absolute right-0 bg-background-200 text-foreground-300 z-50 py-3 rounded-md shadow-lg w-48 list-none text-left"
          }
        >
          <span
            className={
              text
                ? "text-sm font-normal block w-full bg-opacity-80 border-background-400 border-b px-4 pb-4 pt-2"
                : "hidden"
            }
          >
            {text}
          </span>

          {menus.map((menu, i) => (
            <Link
              key={i}
              href={menu.href}
              icon={menu.icon}
              className="block py-3 pl-4 pr-8 w-full text-sm capitalize hover:bg-primary-400 hover:text-white"
            >
              <span className="ml-2 min-w-40">{menu.text}</span>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
