import React from "react";

import Link from "components/Common/Link";
import Icon from "components/Common/Icon";
import UserConfig from "config/user";
import { handleLogout } from "services/auth";

type Props = {
  className?: string;
};
export default function UserSidebar({ className }: Props) {
  return (
    <>
      <div
        className={`fixed top-0 lg:flex lg:flex-col w-64 h-screen px-4 py-8 bg-background-200 border-r border-background-500 ${className}`}
      >
        <div className="relative mt-14">
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <Icon icon="search" />
          </span>

          <input
            type="text"
            className="w-full py-3 pl-10 pr-4 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 focus:outline-none focus:ring"
            placeholder="Search"
          />
        </div>

        <div className="flex flex-col justify-between flex-1 mt-6">
          <nav>
            {UserConfig.navbar.map((link, i) => {
              return (
                <Link
                  key={i}
                  className="flex items-center px-4 py-2 text-foreground-200 bg-background-200 rounded-md hover:bg-primary-400 hover:text-foreground-100"
                  href={link.href}
                  icon={link.icon}
                >
                  {link.title}
                </Link>
              );
            })}
            <Link
              className="flex items-center px-4 py-2 mt-1 text-foreground-200 transition-colors duration-200 transform rounded-md hover:bg-primary-400 hover:text-foreground-100"
              href="/auth/logout"
              icon="user-cog"
              onClick={handleLogout}
            >
              Logout
            </Link>
          </nav>
        </div>
      </div>
    </>
  );
}
