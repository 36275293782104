import React from "react";
import { PageProps } from "gatsby";
import { Router } from "@reach/router";
import UserLayout from "layouts/UserLayout";
import PrivateRoute from "components/Routes/PrivateRoute";
import Route from "components/Routes/Route";
import NewsPage from "templates/news";
import CompanyPage from "pages/company";
import Profile from "components/User/Profile";

export default function Page(props: PageProps) {
  return (
    <UserLayout>
      <Router basepath="/user">
        <Route {...props} path="/news" component={NewsPage} />
        <Route {...props} path="/company" component={CompanyPage} />
        <PrivateRoute {...props} path="/" component={Profile} />
        <PrivateRoute {...props} path="/profile" component={Profile} />
      </Router>
    </UserLayout>
  );
}
