import React, { MouseEventHandler, ReactNode } from "react";

// components
import Footer from "components/Footers/Footer";
import UserNavbar from "components/Navbars/UserNavbar";
import UserSidebar from "components/Sidebars/UserSidebar";
import SEO from "components/Common/SEO";
import IconGoBack from "components/Common/IconGoBack";
import IconGoTop from "components/Common/IconGoTop";

type Props = {
  title?: string;
  path?: string;
  description?: string;
  backButton?: boolean;
  children: ReactNode;
};

export default function UserLayout({
  title,
  path,
  description,
  backButton = false,
  children,
}: Props) {
  return (
    <>
      <SEO title={title} path={path} description={description} />
      <UserNavbar />
      <UserSidebar />
      <div className="lg:ml-64 bg-background-100 text-foreground-200">
        <div className="pb-10 min-h-screen-50">{children}</div>
        <IconGoBack show={backButton} />
        <IconGoTop />
        <Footer />
      </div>
    </>
  );
}
