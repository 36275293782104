import React, { useState } from "react";
import Link from "components/Common/Link";
import Icon from "components/Common/Icon";
import { getUser, isLoggedIn } from "services/auth";
import UserDropdown from "components/Dropdowns/UserDropdown";
import Brand from "components/Common/Brand";
import Config from "config/site";
import UserConfig from "config/user";

type Props = {
  className?: string;
};

export default function Navbar({ className }: Props) {
  const [sidebarShow, setSidebar] = useState(false);
  const user = getUser();

  return (
    <>
      <nav className={`z-50 sticky shadow font-medium text-sm ${className}`}>
        <div className="pl-3 pr-1 py-3">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex flex-initial items-center justify-between">
              <div className="text-left text-sm uppercase  font-semibold">
                <Link href="/" className="py-2 sm:py-1">
                  <Brand className="h-6 brand fill-current" />
                </Link>
              </div>

              <div className="flex md:hidden">
                <button
                  type="button"
                  className="text-gray-200 hover:text-gray-100 focus:outline-none focus:text-gray-100"
                  aria-label="toggle menu"
                  onClick={() => setSidebar(!sidebarShow)}
                >
                  <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
                    <path
                      fillRule="evenodd"
                      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <div
              className={
                (sidebarShow ? "" : "hidden") +
                "flex-1 flex-grow md:flex md:items-center md:justify-between"
              }
            >
              <div className="flex flex-grow flex-col -mx-4 md:flex-row md:items-center md:mx-8">
                {UserConfig.navbar.map((link, i) => {
                  return (
                    <Link
                      key={i}
                      href={link.href}
                      className="p-2 mx-2 mt-2 transition-colors duration-200 transform rounded-md md:mt-0 text-gray-200 hover:bg-yellow-500"
                    >
                      {link.title}
                    </Link>
                  );
                })}
              </div>

              {isLoggedIn() ? (
                <div className="flex items-center mt-4 md:mt-0">
                  <button
                    className="hidden mx-4 md:block text-gray-200 hover:text-gray-100 focus:text-gray-100 focus:outline-none"
                    aria-label="show notifications"
                  >
                    <Icon icon="bell" />
                  </button>

                  <UserDropdown user={user} />
                </div>
              ) : (
                <div className="flex items-center mt-4 md:mt-0">
                  <Link
                    href={Config.loginURL}
                    className="bg-primary-300 text-foreground hover:bg-primary-400"
                  >
                    Login
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
