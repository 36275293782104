export default {
  dropdown: [
    {
      href: "/user/",
      title: "My Page",
      icon: "briefcase",
    },
    {
      href: "/user/profile",
      title: "Profile",
      icon: "bread-slice",
    },
    {
      href: "/user/settings",
      title: "Settings",
      icon: "user-cog",
    },
  ],
  navbar: [
    {
      href: "/user/",
      title: "My Page",
      icon: "briefcase",
    },
    {
      href: "/user/profile",
      title: "Profile",
      icon: "bread-slice",
    },
    {
      href: "/user/settings",
      title: "Settings",
      icon: "user-cog",
    },
  ],
};
